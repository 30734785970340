import { graphql, readInlineData } from "react-relay";

import { checkIsLiveArticle } from "scmp-app/components/article/article-render/live-article/helpers";
import { checkIsPostiesArticle } from "scmp-app/components/article/article-render/posties/helpers";
import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import { checkIsPostiesPage } from "scmp-app/components/pages/posties-pages/helpers";
import {
  checkIsMagazineSectionContent,
  checkIsSectionContent,
} from "scmp-app/components/section/helpers";
import { Variant as SectionVariant } from "scmp-app/pages/section/enums";
import type { helpersAppBarVariantBase$key } from "scmp-app/queries/__generated__/helpersAppBarVariantBase.graphql";

import type { AppBarVariant } from "./types";

export const AppBarColorScheme: Record<AppBarVariant, "light" | "dark"> = {
  "posties/brochure": "light",
  "posties/generic": "light",
  "scmp/generic-light": "light",
  "scmp/generic-light-with-banner-ad": "light",
  "scmp/home": "light",
  "scmp/magazines-style": "light",
  "scmp/plus": "light",
  "scmp/plus/learnmore": "light",
  "scmp/slim": "light",
};

export const getAppBarVariant = (reference: helpersAppBarVariantBase$key) => {
  const content = readInlineData(
    graphql`
      fragment helpersAppBarVariantBase on BaseWithApplicationAndUrlAlias @inline {
        ...helpersCheckIsLiveArticle
        ...helpersCheckIsPlusPage
        ...helpersCheckIsPostiesArticle
        ...helpersCheckIsPostiesPage
        ...helpersCheckIsPostMagazineArticle
        ...helpersCheckIsStyleArticle
        ...helpersSectionContent
        ...helpersStyleSectionContent
      }
    `,
    reference,
  );

  if (
    checkIsStyleArticle(content) ||
    checkIsMagazineSectionContent(content) === SectionVariant.Style
  )
    return "scmp/magazines-style";
  if (checkIsSectionContent(content)) return "scmp/slim";
  if (checkIsPostiesArticle(content) || checkIsPostiesPage(content)) return "posties/generic";
  if (checkIsLiveArticle(content).isLiveArticle) return "scmp/generic-light-with-banner-ad";

  return "scmp/generic-light";
};

export const getShouldShowAdBelowAppBar = (appBarVariant: AppBarVariant) => {
  switch (appBarVariant) {
    case "scmp/generic-light":
    case "scmp/home":
      return true;
    default:
      return false;
  }
};
