import { notEmpty } from "@product/scmp-sdk";
import { type FunctionComponent, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { ContentSchemaRender } from "scmp-app/components/schema-render/content";
import type { contentSubHeadlineContent$key } from "scmp-app/queries/__generated__/contentSubHeadlineContent.graphql";

import type { ContentSubHeadlineContainerProps } from "./styles";
import { ContentSubHeadlineContainer } from "./styles";

export type Props = {
  className?: string;
  disableFallbackEmptyToSummary?: boolean;
  reference: contentSubHeadlineContent$key;
  variant?: ContentSubHeadlineContainerProps["$variant"];
};

export const ContentSubHeadline: FunctionComponent<Props> = ({
  className,
  disableFallbackEmptyToSummary,
  reference,
  variant,
}) => {
  const content = useFragment(
    graphql`
      fragment contentSubHeadlineContent on Content {
        subHeadline {
          json
        }
        summary {
          json
        }
        ...contentSchemaRenderContent
      }
    `,
    reference,
  );

  const schema = useMemo(() => {
    const check = notEmpty(content.subHeadline?.json);
    if (disableFallbackEmptyToSummary && !check) return;
    return check ? content.subHeadline?.json : content.summary?.json;
  }, [content.subHeadline?.json, content.summary?.json, disableFallbackEmptyToSummary]);

  if (!notEmpty(schema)) return null;

  return (
    <ContentSubHeadlineContainer $variant={variant} className={className}>
      <ContentSchemaRender reference={content} schema={schema} />
    </ContentSubHeadlineContainer>
  );
};

ContentSubHeadline.displayName = "ContentSubHeadline";
