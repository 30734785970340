import { graphql, readInlineData } from "react-relay";

import { checkIsPostiesArticle } from "scmp-app/components/article/article-render/posties/helpers";
import { checkIsPlusPage } from "scmp-app/components/pages/plus-pages/helpers";
import { checkIsPostiesPage } from "scmp-app/components/pages/posties-pages/helpers";
import type { helpersAppBarVariantBase$key } from "scmp-app/queries/__generated__/helpersAppBarVariantBase.graphql";

export type Application = "scmp" | "posties" | "plus";
export type Theme = "default" | "print-article";

export const getApplication = (reference: helpersAppBarVariantBase$key): Application => {
  const content = readInlineData(
    graphql`
      fragment helpersApplicationBase on BaseWithApplicationAndUrlAlias @inline {
        ...helpersCheckIsPostiesArticle
        ...helpersCheckIsPostiesPage
        ...helpersCheckIsPlusPage
      }
    `,
    reference,
  );

  if (checkIsPostiesArticle(content) || checkIsPostiesPage(content)) return "posties";
  if (checkIsPlusPage(content)) return "plus";

  return "scmp";
};
