import { graphql, readInlineData } from "react-relay";

import { article as articleData } from "scmp-app/data";
import type { helpersCheckIsLiveArticle$key } from "scmp-app/queries/__generated__/helpersCheckIsLiveArticle.graphql";

import { ItemNamePrefix } from "./consts";

export const getTimelineItemClassName = (entityUuid?: string | null) => {
  if (!entityUuid) return;
  return `${ItemNamePrefix.Timeline}_${entityUuid}`;
};

export const getContentItemClassName = (entityUuid?: string | null) => {
  if (!entityUuid) return;
  return `${ItemNamePrefix.Content}_${entityUuid}`;
};

export const makeSureContentItemExists = (entityUuid: string, retry = 5, debounce = 1000) =>
  new Promise<void>(resolve => {
    const run = (currentTime: number, lastTime = 0, _retry = retry) => {
      if (_retry === 0) {
        resolve();
        return;
      }
      if (currentTime - lastTime < debounce) {
        setTimeout(() => {
          requestAnimationFrame(time => run(time, currentTime, _retry));
        }, debounce);
        return;
      }
      if (document.querySelector(`.${getContentItemClassName(entityUuid)}`) === null) {
        requestAnimationFrame(time => run(time, currentTime, _retry - 1));
        return;
      }

      resolve();
    };
    requestAnimationFrame(run);
  });

export const checkIsLiveArticle = (reference: helpersCheckIsLiveArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckIsLiveArticle on Article @inline {
        flattenTypeIds
        liveArticle {
          status
        }
      }
    `,
    reference,
  );

  const isLiveArticle =
    article.flattenTypeIds?.includes(articleData.types.live.entityUuid) ?? false;
  const isLive = article.liveArticle?.status ?? false;

  return { isLive, isLiveArticle };
};
